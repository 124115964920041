.App {
 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#GameDiv{
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html {
  -ms-touch-action: none;
}

body, canvas, div {
  display: block;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul{
  padding-left: 16px;
  line-height: 1.5;
}

p{
  line-height: 1.5;
}

/* Remove spin of input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  margin: 0;

  cursor: default;
  color: #888;
  background-color: #333;

  text-align: center;
  font-family: Helvetica, Verdana, Arial, sans-serif;

  display: flex;
  flex-direction: column;
}

canvas {
  background-color: rgba(0, 0, 0, 0);
}

#GameDiv, #Cocos3dGameContainer, #GameCanvas {
  width: 100%;
  height: 100%;
}

:root {
  --safe-top: env(safe-area-inset-top);
  --safe-right: env(safe-area-inset-right);
  --safe-bottom: env(safe-area-inset-bottom);
  --safe-left: env(safe-area-inset-left);
}

@keyframes slideIn {
  from{
    left:0%;
    transform:translateX(-100%)
  }
  to{
    left:50%;
    transform:translateX(-50%)
  }
}
@keyframes slideOut {
  from{
    left:50%;
    transform:translateX(-50%)
  }
  to{
    left:100%;
    transform:translateX(100%)
  }
}

@keyframes fadeIn {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@keyframes fadeOut {
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}

@keyframes slideUp {
  from{
    transform:translateY(25px)
  }
  to{
    transform:translateY(0px)
  }
}

@keyframes slideOutUp {
  from{
    transform:translateY(0px)
  }
  to{
    transform:translateY(-15px)
  }
}
@keyframes swipe-up {
  from{
    top:10%
  }
  to{
    top:80%
  }
}